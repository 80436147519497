<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="842"
    :closable="false"
    centered
    :maskClosable="false"

  >
    <!--    :ok-button-props="{ props: { disabled: submitBtn } }"-->
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>{{ typeText }}销售机会
      </span>
    </div>
    <div class="cancelContent">
      <div class="topInfoBox">
        <p><span>*</span>用户所在地区：</p>
        <div class="rightBox">
          <address-from width="418" height="32" @onAddressChange="onAddressChange" :addressDftVal="addressHx" />
         <!-- <a-input placeholder="请输入街道号、楼牌号" class="userDsInputress" v-model="userAdress" style="width: 418px"></a-input> -->
          <a-auto-complete
            class="userDsInputress"
            style="margin-top: 16px;width:418px;border: none"
            placeholder="请输入街道号、楼牌号"
            show-search
            v-model="userAdress"
            :value="userAdress"
            :data-source="companyList"
            :filter-option="false"
            :defaultActiveFirstOption="true"
            @search="getAdress"
            @select="searcha"

          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />

            <template slot="dataSource">
              <a-select-option
                :value="item.name"
                v-for="(item,index) in companyList"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </template>
          </a-auto-complete>
        </div>
      </div>
      <div class="bottomBox">
        <p>用户购买意向：</p>
        <div class="rightBox">
          <div class="proBox"
               v-for="(item,index) in productList"
               :key="item.id"
          >
            <div class="proItemBox">
              <a-select
                class="chose-account"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChangespart(item)"
                v-model="item.spartId"
                placeholder="请选择品类"
              >
                <a-select-option
                  :value="item.code"
                  v-for="item in spartList"
                  :key="item.code"
                >{{ item.name }}
                </a-select-option>
              </a-select>
               <a-auto-complete
                class="chose-account2"
                placeholder="请输入产品信息"
                show-search
                v-model="item.product"
                :value="item.product"
                :data-source="item.proList"
                :filter-option="false"
                :defaultActiveFirstOption="true"
                @search="getProduct(item,$event)"
              >
                <a-spin v-if="item.fetching" slot="notFoundContent" size="small" />
                <template slot="dataSource">
                  <a-select-option
                    :value="item"
                    v-for="(item) in item.proList"
                    :key="item"
                  >
                    {{ (item).trim() }}
                  </a-select-option>
                </template>
              </a-auto-complete>
              <a-select
                class="chose-account"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChangeBudget(item)"
                v-model="item.budget"
                placeholder="请选择购买预算"
              >
                <a-select-option
                  :value="item.name"
                  v-for="item in budgetList"
                  :key="item.code"
                >{{ item.name }}
                </a-select-option>
              </a-select>
              <a-date-picker class="chose-account2"
                             @change="(data,dateString)=>changeTime(data,dateString,item)"
                             placeholder="请选择计划购买时间"
                             v-model="item.planBuyTimeStr" />
            </div>
            <div class="close">
              <a-popconfirm
                placement="topRight"
                title="确认删除该产品？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="delectProItem(item,index)"
              >
                <i class="iconfont icon-guanbi-gouwuche iconfontgwc"></i>
              </a-popconfirm>
            </div>
          </div>
          <div class="addPro" @click="addProItem">
            <img src="@/assets/baseInfo/录入添加@2x.png">新增意向产品
          </div>

        </div>

      </div>
    </div>
    <div class="cancelInfo">
    </div>
  </a-modal>
</template>
<script>
import { getDefevtiveProByMatkl, potentialSpart } from "@/views/userOperations/IntendedUserEntry/api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { getUser, saveSale, upDateSale } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
import { getAdressapi } from "@/views/settlement/api";

export default {
  name: "cancel-modal",
  data() {
    return {
      userAdress: "",// 详细地址
      shengCode: "",// 省code 传参使用
      shiCode: "",// 市code传参使用
      quCode: "",// 区code
      xianCode: "",// 镇code
      // 产品信息
      productList: [
        {
          id: "",
          spartId: undefined,
          product: "",
          budget: undefined,
          planBuyTimeStr: "",
          proList: [],
          fetching: false
        }
      ],
      spartList: [],   // 意向品类
      budgetList: [],// 购买预算
      addressHx: [],// 回显数据
      companyList: [],
      fetching: false,

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    typeText: {
      type: String,
      default: ""
    },
    Info: {
      type: Object,
      default: function() {
        return {};
      }
    },
    userId: {
      type: String,
      default: ""
    }
  },

  watch: {
    // 监听传过来的数据
    Info: function(val, old) {
      if (val) {
        if (this.typeText == "编辑") {
          this.Info = val;
          // 编辑的话拿到省市区县的值 传给组件实现回显
          this.addressHx = [this.Info.provinceCode, this.Info.cityCode, this.Info.districtCode, this.Info.townCode];
          this.userAdress = val.address;
          this.shengCode = this.Info.cityCode;
          this.shiCode = this.Info.provinceCode;
          this.quCode = this.Info.districtCode;
          this.xianCode = this.Info.townCode;
          // 如果接口里销售机会列表大于0 就给本地的赋值
          if (val.detailList.length > 0) {
            this.productList = [];
            val.detailList.forEach(item => {
              this.productList.push({
                id: item.id,
                product: item.product,
                budget: item.budget,
                spartId: item.spartId,
                planBuyTimeStr: item.planBuyTimeStr
              });
            });
          } else {
            this.addressHx = [];
            this.productList = [
              {
                id: "",
                spartId: undefined,
                product: "",
                budget: undefined,
                planBuyTimeStr: "",
                proList: [],
                fetching: false
              }
            ];
          }
        }
      } else {
        this.Info = {};
        this.userAdress = "";
        this.shengCode = "";
        this.shiCode = "";
        this.quCode = "";
        this.xianCode = "";
        this.addressHx = [];
        this.productList = [
          {
            id: "",
            spartId: undefined,
            product: "",
            budget: undefined,
            planBuyTimeStr: "",
            proList: [],
            fetching: false
          }
        ];
      }
    },

    typeText: function(val) {
      if (val == "新增") {
        this.addressHx = [];
        this.Info = {};
        this.userAdress = "";
        this.shengCode = "";
        this.shiCode = "";
        this.quCode = "";
        this.xianCode = "";
        this.productList = [
          {
            id: "",
            spartId: undefined,
            product: "",
            budget: undefined,
            planBuyTimeStr: "",
            proList: [],
            fetching: false
          }
        ];
      }
    },
    visible:function(val){
      if(val == true) {
        if(this.typeText== '新增') {
          // 顶部用户信息
          let data = {
            userId: this.userId
          };
          getUser(data).then(res => {
            if(res.data.code == 0){
              this.addressHx = res.data.data.provinceCode?[res.data.data.provinceCode,res.data.data.cityCode,res.data.data.districtCode,res.data.data.townCode]:[]
              this.shengCode = res.data.data.provinceCode?res.data.data.provinceCode:'';
              this.shiCode = res.data.data.cityCode?res.data.data.cityCode:'';
              this.quCode = res.data.data.districtCode?res.data.data.districtCode:'';
              this.xianCode = res.data.data.townCode?res.data.data.townCode:'';
              this.userAdress = res.data.data.address
              if(this.addressHx.length<=0) {
                // 获取登录接口的数据
                this.addressHx = this.$store.state.user.userInfo.customer.currentTradeCode?[
                  this.$store.state.user.userInfo.customer.currentTradeCode,
                  this.$store.state.user.userInfo.customer.currentCityCode,
                  this.$store.state.user.userInfo.customer.currentAreaCode,
                  this.$store.state.user.userInfo.customer.townCode
                ]:[]
                this.shengCode =  this.$store.state.user.userInfo.customer.currentTradeCode? this.$store.state.user.userInfo.customer.currentTradeCode:'';
                this.shiCode =  this.$store.state.user.userInfo.customer.currentCityCode? this.$store.state.user.userInfo.customer.currentCityCode:'';
                this.quCode =  this.$store.state.user.userInfo.customer.currentAreaCode? this.$store.state.user.userInfo.customer.currentAreaCode:'';
                this.xianCode =  this.$store.state.user.userInfo.customer.townCode? this.$store.state.user.userInfo.customer.townCode:'';
                this.userAdress = ''
              }
            } else {
              // 获取登录接口的数据
              this.addressHx = this.$store.state.user.userInfo.customer.currentTradeCode?[
                this.$store.state.user.userInfo.customer.currentTradeCode,
                this.$store.state.user.userInfo.customer.currentCityCode,
                this.$store.state.user.userInfo.customer.currentAreaCode,
                this.$store.state.user.userInfo.customer.townCode
              ]:[]
              this.shengCode =  this.$store.state.user.userInfo.customer.currentTradeCode? this.$store.state.user.userInfo.customer.currentTradeCode:'';
              this.shiCode =  this.$store.state.user.userInfo.customer.currentCityCode? this.$store.state.user.userInfo.customer.currentCityCode:'';
              this.quCode =  this.$store.state.user.userInfo.customer.currentAreaCode? this.$store.state.user.userInfo.customer.currentAreaCode:'';
              this.xianCode =  this.$store.state.user.userInfo.customer.townCode? this.$store.state.user.userInfo.customer.townCode:'';
              this.userAdress = ''
            }
          });
        }
      }
    }
  },
  mounted() {
    this.getmaterialGrouplist();
    this.getBuyList();

  },
  methods: {
    //查询数据
    searcha() {

    },
    getAdress(value) {
      let id = null;
      if (this.quCode) {
        id = this.quCode;
      } else if (this.shiCode) {
        id = this.shiCode;
      } else if (this.shengCode) {
        id = this.shengCode;
      } else {
        id = 0;
      }

      this.userAdress = value;
      let a = {
        searchstr: this.userAdress,
        region: id
      };
      let b = {
        searchstr: this.userAdress
      };
      let data = id !== 0 ? a : b;
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList = [];
        }

        this.fetching = false;
      });

    },
    // 获取产品
    getProduct(item, value) {
      let data = {
        term: value
      };
      item.fetching = true;
      getDefevtiveProByMatkl(data).then(res => {
        if (res.data.list.length > 0 && res.data.list !== null) {
          item.proList = [...new Set(res.data.list)]; //缓存列表数据，前端分页
        } else {
          item.proList = [];
        }
        item.fetching = false;
        this.$forceUpdate()
      });
    },
    // 新增产品
    addProItem() {
      this.productList.push({
        id: "",
        spartId: undefined,
        product: "",
        budget: undefined,
        planBuyTimeStr: "",
        proList: [],
        fetching: false
      });

    },
    // 商品行数据删除
    delectProItem(item, index) {
      // 如果当前产品行数据 大于1 就减去  只剩一个 就清空
      if (this.productList.length > 1) {
        this.productList.splice(index, 1);
      } else {
        this.productList.forEach(item => {
          item.id = "";
          item.spartId = undefined;
          item.product = "";
          item.budget = undefined;
          item.planBuyTimeStr = "";
          item.proList = [];
          item.fetching = false;
        });
      }

    },
    // 获取意向品类
    getmaterialGrouplist() {
      let data = {};
      potentialSpart(data).then(res => {
        if (res.data.code == 0) {
          this.spartList = res.data.list;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 获取购买意向接口
    getBuyList() {
      let data = {
        pid: 14927471376
      };
      focusonremarkplz(data).then(res => {
        this.budgetList = res.data.list;
      });
    },
    // 修改意向品类
    handleChangespart(item) {
    },
    // 修名称
    changeProName(item) {
    },
    // 修改预算
    handleChangeBudget(item) {
    },
    // 修改产品信息时间
    changeTime(date, dateString, item) {
      item.planBuyTimeStr = dateString;
    },
    onAddressChange(addressValArr) {
      if(addressValArr&&addressValArr.length == 2) {
        this.quCode = '';
      }
      // 长度如果等于3 就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.xianCode = '';
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.shengCode = item;
          }
          if (index == 1) {
            this.shiCode = item;
          }
          if (index == 2) {
            this.quCode = item;
          }
          if (index == 3) {
            this.xianCode = item;
          }
        });
      }else  {
        this.shengCode = ''
        this.shiCode = ''
        this.quCode = ''
        this.xianCode = ''
      }

      // ['340000000000', '340600000000', '340603000000', '340603400000', __ob__: Observer]
      // ..
    },

    handleOk() {
      if (!this.shengCode || !this.shiCode || !this.quCode || !this.xianCode) {
        this.$message.warning("请选择用户所在地址");
        return;
      }
      if(!this.userAdress) {
        this.$message.warning("请输入详细地址");
        return;
      }
      // 数组重组
      var ppRouList = [];
      this.productList.forEach((item, index) => {
        ppRouList.push({
          id: item.id ? item.id : "",
          spartId: item.spartId ? item.spartId : "",
          product: item.product ? item.product : "",
          budget: item.budget ? item.budget : "",
          planBuyTimeStr: item.planBuyTimeStr ? item.planBuyTimeStr : ""
        });
      });
      for (var i = 0; i < ppRouList.length; i++) {
        if (!ppRouList[i].spartId) {
          this.$message.warning(`第${i + 1}行品类不能为空,请选择`);
          return;
        }
        // if (!ppRouList[i].product) {
        //   this.$message.warning(`第${i + 1}行产品不能为空,请输入`);
        //   return;
        // }
        // if (!ppRouList[i].budget) {
        //   this.$message.warning(`第${i + 1}行购买预算不能为空,请选择`);
        //   return;
        // }
        // if (!ppRouList[i].planBuyTimeStr) {
        //   this.$message.warning(`第${i + 1}行计划购买时间不能为空,请选择`);
        //   return;
        // }
      }
      // 如果是新增的话 传值如下 并且数据清空
      if (this.typeText == "新增") {
        let data = {
          "townCode": this.xianCode,
          "address": this.userAdress,
          "userId": this.userId,
          "spartName": "",
          "detailList": ppRouList
        };
        this.Info = {};
        this.userAdress = "";
        this.shengCode = "";
        this.shiCode = "";
        this.quCode = "";
        this.xianCode = "";
        this.productList = [
          {
            spartId: undefined,
            product: "",
            budget: undefined,
            planBuyTimeStr: "",
            proList: [],
            fetching: false,
          }
        ];
        this.addressHx = [];
        this.$emit("cancelReasonHandOk", data);
        this.$emit("update:visible", false);
      } else {
        let data = {
          "townCode": this.xianCode,
          "address": this.userAdress,
          "id": this.Info.id,
          "userId": this.userId,
          "spartName": "",
          "detailList": ppRouList
        };

        this.Info = {};
        this.userAdress = "";
        this.shengCode = "";
        this.shiCode = "";
        this.quCode = "";
        this.xianCode = "";
        this.productList = [
          {
            spartId: undefined,
            product: "",
            budget: undefined,
            planBuyTimeStr: "",
            proList: [],
            fetching: false,
          }
        ];
        this.$emit("cancelReasonHandOk", data);
        this.$emit("update:visible", false);
      }
    },
    cancle() {
      this.$emit("update:visible", false);
      this.addressHx = [];
      this.Info = {};
      this.userAdress = "";
      this.shengCode = "";
      this.shiCode = "";
      this.quCode = "";
      this.xianCode = "";
      this.productList = [
        {
          spartId: undefined,
          product: "",
          budget: undefined,
          planBuyTimeStr: "",
          proList: [],
          fetching: false,
        }
      ];
      this.productList = [
        {
          spartId: undefined,
          product: "",
          budget: undefined,
          planBuyTimeStr: "",
          proList: [],
          fetching: false,
        }
      ];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }
  }


};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;


    .ant-modal-body {
      padding: 30px 0px 0 40px;

      .cancelContent {
        text-align: left;
        padding: 0 40px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        margin-left: 72px;
        max-height:500px;
        overflow:auto;

        .topInfoBox {
          display: flex;

          p {
            width: 156px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;

            span {
              color: #D9161C;
            }
          }

          .rightBox {
            margin-left: 8px;

            .userDsInputress {
              margin: 16px 0 24px 0;
            }
          }
        }

        .bottomBox {
          display: flex;
          //max-height: 300px;
          //overflow: auto;
          //flex-direction: row;

          p {
            width: 104px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-top: 18px;
            text-align: right;

            span {
              color: #D9161C;
            }
          }

          .rightBox {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .proBox {
              display: flex;
              justify-content: center;
              align-items: center;

              .proItemBox {
                width: 468px;
                height: 112px;
                background: #F2F3F5;
                border-radius: 6px;
                margin-bottom: 16px;

                .chose-account {
                  width: 180px;
                  height: 32px;
                  margin: 16px 16px 0px 16px;
                }

                .chose-account2 {
                  width: 240px;
                }
              }

              .close {
                margin-left: 16px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: #AAAAAA;
                cursor: pointer;

                .iconfontgwc {
                  margin-top: -10px;
                  font-size: 20px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                }

                .iconfontgwc:hover {
                  margin-top: -10px;
                  // background-color: #00AAA6;
                  // margin-top: -4px;
                  background-image: url('./../../../../assets/cart/close.png');
                  color: #fff;
                  background-size: 100%;

                }

              }
            }

            .addPro {
              display: flex;
              justify-content: center;
              //align-items: center;
              width: 418px;
              height: 32px;
              border-radius: 4px;
              border: 1px dashed #EEEEEE;
              cursor: pointer;
              margin-left: -35px;


              img {
                width: 8px;
                height: 8px;
                margin-right: 4px;
                margin-top: 12px;
              }

              font-size: 14px;
              font-weight: 400;
              color: #1890FF;
              line-height: 32px;
            }

            .addPro:hover {
              color: #1890FF;
              border: 1px dashed #1890FF;
              background: rgba(24, 144, 255, 0.08);
            }


          }

        }

        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }

      .cancelInfo {
        padding: 24px 0 6px 0px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 78px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float: left;
          padding-left: 11px;
          margin-right: 9px;
        }

        .ant-input {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>



<template>
  <div class="RecordBox">

    <div
      v-for="(item,index) in recoredList"
      :key="item.id"
      class="RecordBoxEvery"
    >
      <div class="zjsx" v-show="(recoredList.length > 1 && index !== (recoredList.length-1))"></div>
      <p class="timePart"  v-if="item.finish == '未完成'" >{{ item.visitDateStr }}</p>
      <p class="timePart"  v-else >{{ item.createDateStr}}</p>

      <div class="imgBox">
        <img v-show="item.finish == '未完成'"    src="../../../../assets/IntentionList/B-时间@2x.png" alt="">
        <img v-show="item.finish == '新建'"    src="../../../../assets/IntentionList/编组 4@2x.png" alt="">
        <img v-show="item.finish == '超时'"   src="../../../../assets/IntentionList/编组 6备份@2x.png" alt="">
        <img v-show="item.finish == '完成'" src="../../../../assets/IntentionList/正确备份@2x.png" alt="">
      </div>

<!--      电话跟进展示-->
      <div class="rightPart" v-if="item.finish=='完成'">
        <p class="title" >已{{item.finishVisitWayName}}{{themText}}</p>
        <p class="message">{{themText}}结果 | {{item.finishVisitResult}}</p>
        <p class="message" v-show="item.statusMemo">更改意向状态  | {{item.statusMemo}}</p>
        <p class="message" v-show="item.updateMemo">修改意向产品  | {{item.updateMemo}}</p>
        <p class="message" v-show="item.labelMemo">修改用户标签  | {{item.labelMemo}}</p>
         <div class="bigImg" v-if="item.imgArr.length>0">
           <div class="imgBoxEEE"
            v-for="(itemW,indexs) in item.imgArr"
                :key="indexs"
           >
             <img @click="openImg(itemW)" :src="itemW" alt="">
           </div>
         </div>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
        <p class="message1">由「{{item.creator}}」{{themText=='回访'?'创建':'跟进'}}</p>
      </div>
<!--      计划-->
      <div class="rightPart" v-else-if="item.finish=='未完成'">
        <p :class="item.finish == '未完成'?'titleActive':'title'">{{item.visitWayName}}{{themText}}（计划）</p>
        <p class="message">计划{{themText}}主题 | {{item.visitResult}}</p>
        <p class="message1">由「{{item.creator}}」创建</p>
      </div>
<!--      未当面跟进-->
      <div class="rightPart" v-else-if="item.finish=='超时'">
        <p class="title">未{{item.visitWayName?item.visitWayName:''}}{{themText}}</p>
        <p class="message1">{{item.visitResult}}</p>
        <p class="message1">由「{{item.creator}}」创建</p>
      </div>
<!--      新建-->
      <div class="rightPart" v-else-if="item.finish == '新建'">
        <p class="title">{{item.visitWayName?item.visitWayName:''}}</p>
        <p class="message1">{{item.visitResult}}</p>
        <p class="message1">由「{{item.creator}}」创建</p>
      </div>
<!--             其余-->
      <div class="rightPart" v-else>
        <p class="title">{{item.finishVisitResult?item.finishVisitResult:''}}</p>
        <p class="message1">{{item.visitResult}}</p>
        <p class="message1">由「{{item.creator}}」创建</p>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      previewVisible:false,
      previewImage:''
    };
  },
  props: {
    recoredList: {
      type: Array,
      default: () => []
    },
    themText:{
      type: String,
      default: '跟进'
    }

  },

  watch: {
    recoredList: function(val, old){
      if(val.length > 0) {
        val.forEach(item=>{
          item.imgArr = []
          item.imgArr =  item.pictureId?item.pictureId.split(','):[]
        })
      }

    }


  },
  mounted() {
  },
  methods: {
    openImg(item){
      this.previewImage = item
      this.previewVisible = true
    },
    handleCancel() {
      this.previewImage = ''
      this.previewVisible = false
    }
  }
};
</script>
<style lang="less" scoped>
.RecordBox {
  height: 100%;
  margin: 32px 0 0 110px;
  display: flex;
  flex-direction: column;


  .RecordBoxEvery {
    //z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: flex-start;
    height: 100%;
    padding-bottom: 33px;
    position: relative;
    .zjsx {
      width: 1px;
      height: 100%;
      background: #EEEEEE;
      position: absolute;
      left: 153px;
      z-index:10
    }
    .zjsxWC {
      width: 1px;
      height: calc(100% - 149px);
      background: #EEEEEE;
      position: absolute;
      left: 153px;
      z-index:10
    }
    .zjsxWWC {
      width: 1px;
      height: calc(100% - 55px);
      background: #EEEEEE;
      position: absolute;
      left: 153px;
      z-index:10
    }
    .zjsxQT {
      width: 1px;
      height: calc(100% - 55px);
      background: #EEEEEE;
      position: absolute;
      left: 153px;
      z-index:10
    }
    .imgBox{
      width: 24px;
      height: 24px;
      z-index:500;
      position: relative;

      //margin: 0 16px 0 23px;
      img{
        z-index:500;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 0 16px 0 23px;
      }
    }

    .timePart{
      font-size: 12px;
      font-weight: 400;
      color: #262626;
      margin-top: 3px;
    }
    .timePartActive{
      font-size: 12px;
      font-weight: 400;
      color: #00AAA6;
      margin-top: 3px;
    }
    .rightPart {
      p:first-child {
        height: 14px;
        margin-bottom: 8px;
      }
      .bigImg {
        display: flex;
        flex-direction: row;

        .imgBoxEEE {
          width: 54px;
          height: 54px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          //background: #00AAA6;
          margin: 8px 6px 0 0;
          cursor: pointer;
          img {
            width:100%;
            height: 100%;

          }
        }
      }

      margin-left:40px;
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
      }
      .titleActive {
        color: #00AAA6;
        font-size: 14px;
        font-weight: 500;
      }
      .message {
        font-size: 12px;
        font-weight: 400;
        color: #262626;
        width: 500px;
        //line-height: 12px;
      }
      .message1 {
        font-size: 12px;
        font-weight: 400;
        color: #777777;
        width:500px
      }
    }
  }
  .RecordBoxEvery:last-child{
    margin-bottom: 0;
  }
}
</style>

